import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import "aos/dist/aos.css"
import { Helmet } from "react-helmet"

//import $ from "jquery"
//import Popper from "popper.js"
//import "bootstrap/dist/js/bootstrap.bundle"

const IndexPage = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Smart-T - Éditeur de solution de gestion et de paiement Web & Mobile
        </title>
        <link rel="canonical" href="/" />
      </Helmet>
      <Layout>
        <SEO title="Accueil" keywords={[`smart`]} />
      </Layout>
    </>
  )
}

export default IndexPage
